<template>
  <div v-if="space" class="page-content has-padding">
    <section class="section is-small">
      <div class="container">
        <nav class="navbar">
          <a @click="$router.go(-1)" class="navbar-item">
            <span class="icon is-small">
              <font-awesome-icon :icon="['fas', 'chevron-left']" />
            </span>
            <span>Back</span>
          </a>
        </nav>
      </div>
    </section>

    <section class="section is-small">
      <div class="container">
        <ui-page-hero
          additionalTitleClass="no-margin-bottom"
          :title="'Space: ' + space.Name"
        />
      </div>
    </section>

    <section class="section is-small">
      <div class="container">
        <div class="columns">
          <div class="column">
            <SpaceData />
          </div>
          <div class="column"></div>
        </div>
      </div>
    </section>

    <section v-if="locationState" class="section is-small">
      <div class="container">
        <div class="tabs is-boxed">
          <ul>
            <li
              v-for="meetingType in locationState.Meetingtypes"
              @click="setSelectedMeetingtypeId(meetingType.MeetingtypeId)"
              :class="{
                'is-active':
                  meetingType.MeetingtypeId === selectedMeetingtypeId,
              }"
              :key="meetingType.MeetingtypeId"
            >
              <a>{{ meetingType.MeetingtypeId | getMeetingtypeName }}</a>
            </li>
          </ul>
        </div>
      </div>
    </section>

    <div :key="'ms' + selectedMeetingtypeId">
      <section v-if="!selectedMeetingtype" class="section is-small">
        <div class="container">
          <article class="message is-warning">
            <div class="message-body">
              {{ selectedMeetingtypeId | getMeetingtypeName }} is not activated
              for this space.
            </div>
          </article>

          <div class="has-text-centered">
            <a
              @click="addMeetingtype(selectedMeetingtypeId)"
              class="button is-primary"
            >
              <span class="icon">
                <font-awesome-icon :icon="['fas', 'plus']" />
              </span>
              <span>Add</span>
            </a>
          </div>
        </div>
      </section>

      <section v-if="selectedMeetingtype" class="section is-small">
        <div class="container">
          <div class="columns">
            <div class="column is-half">
              <SpaceImages :meetingtypeId="selectedMeetingtypeId" />
            </div>

            <div
              v-if="selectedMeetingtype && selectedMeetingtypeId === 1"
              class="column"
            >
              <SpaceConfigurations
                :meetingtypeId="selectedMeetingtypeId"
                :configurations="space.Settings"
                :key="'spaceConfiguration' + new Date().getTime()"
                class="has-margin-bottom"
              />

              <SpaceEventTypes
                v-if="selectedMeetingtypeId === 1"
                class="has-margin-top"
              />
            </div>
          </div>
        </div>
      </section>

      <section v-if="selectedMeetingtype" class="section is-small">
        <div class="container">
          <div class="columns">
            <div class="column">
              <SpaceMeetingtype :meetingtypeId="selectedMeetingtypeId" />
            </div>
            <div class="column">
              <SpaceChannels :meetingtypeId="selectedMeetingtypeId" />
            </div>
          </div>
        </div>
      </section>

      <section v-if="selectedMeetingtype" class="section is-small">
        <div class="container">
          <SpaceText
            :meetingtypeId="selectedMeetingtypeId"
            :descriptions="space.Descriptions"
          />
        </div>
      </section>

      <section v-if="selectedMeetingtype" class="section is-small">
        <div class="container">
          <SpacePrices :meetingtypeId="selectedMeetingtypeId" />
        </div>
      </section>

      <section
        class="section is-small"
        v-if="selectedMeetingtype && selectedMeetingtypeId === 1"
      >
        <div class="container">
          <SpaceLinks :links="space.Links" />
        </div>
      </section>

      <section class="section is-small">
        <div class="container">
          <SpaceBlockRules :meetingtypeId="selectedMeetingtypeId" />
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import spaceProvider from '@/providers/space'

// const IsPinned = () => import('@/components/Pins/IsPinned')
const SpaceChannels = () => import('@/components/Spaces/SpaceChannels')
const SpaceBlockRules = () => import('@/components/Spaces/SpaceBlockRules')
const SpaceData = () => import('@/components/Spaces/SpaceData')
const SpaceEventTypes = () => import('@/components/Spaces/SpaceEventTypes')
const SpaceConfigurations = () =>
  import('@/components/Spaces/SpaceConfigurations')
const SpaceImages = () => import('@/components/Spaces/SpaceImages')
const SpaceLinks = () => import('@/components/Spaces/SpaceLinks')
const SpaceMeetingtype = () => import('@/components/Spaces/SpaceMeetingtype')
const SpacePrices = () => import('@/components/Spaces/SpacePrices')
const SpaceText = () => import('@/components/Spaces/SpaceText')

export default {
  components: {
    // 'is-pinned': IsPinned,
    SpaceBlockRules,
    SpaceChannels,
    SpaceData,
    SpaceEventTypes,
    SpaceConfigurations,
    SpaceImages,
    SpaceLinks,
    SpaceMeetingtype,
    SpacePrices,
    SpaceText,
  },

  data() {
    return {
      channelId: Number(this.$route.params.channelId),
      locationId: Number(this.$route.params.locationId),
      modalTitle: '',
      selectedMeetingtype: null,
      selectedMeetingtypeId: 0,
      spaceId: Number(this.$route.params.spaceId),
      mSpace: null,
    }
  },

  computed: {
    ...mapState('locationStore', ['locationState']),
    ...mapState('spaceStore', ['space']),
  },

  created() {
    this.mSpace = JSON.parse(JSON.stringify(this.space))

    this.getSpaceData()
  },

  beforeDestroy() {
    this.setSpace(null)
  },

  methods: {
    ...mapMutations('spaceStore', ['setSpace']),

    getSpaceData() {
      let self = this
      spaceProvider.methods.getSpaceById(self.spaceId).then((response) => {
        if (response.status === 200) {
          self.setSpace(response.data)
          self.mSpace = response.data

          this.setSelectedMeetingtypeId(
            this.space.Meetingtypes[0].MeetingtypeId
          )
        }
      })
    },

    setSelectedMeetingtypeId(meetingtypeId) {
      let self = this
      self.selectedMeetingtype = null
      self.selectedMeetingtypeId = meetingtypeId

      let meetingtypeIndex = self.space.Meetingtypes.findIndex(
        (m) => m.MeetingtypeId === meetingtypeId
      )
      if (meetingtypeIndex > -1) {
        self.selectedMeetingtype = self.space.Meetingtypes[meetingtypeIndex]
      }
    },

    addMeetingtype(meetingtypeId) {
      let spaceMeetingtype = {
        SpaceId: this.spaceId,
        MeetingtypeId: meetingtypeId,
        IsShareAble: meetingtypeId === 1 ? false : true,
        IsActive: false,
        CapacityControl: true,
        Capacity: 1,
        MinimumDuration: 0,
        NrOfHoursBeforeStart: 0,
      }

      this.space.Meetingtypes.push(spaceMeetingtype)
      this.saveSpace()

      this.setSelectedMeetingtypeId(meetingtypeId)
    },

    saveSpace() {
      let self = this

      spaceProvider.methods
        .updateSpace(self.mSpace)
        .then((response) => {
          if (response.status === 200) {
            self.setSpace(response.data)
          }
        })
        .catch((error) => {
          //console.log(error.response)
        })
        .finally(() => {})
    },
  },
}
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
